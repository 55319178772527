// copy from variables.module.css
// TO FIX: follow bootstrap or common css framework
export default {
  xl: 1280,
  lg: 1024,
  md: 850,
  sm: 767,
  xs: 550,
  xxs: 330,
};
