import React from 'react';

const CloseNoCircle = props => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 148 148"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>x</title>
    <g id="icons" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="x" fillRule="nonzero">
        <path
          d={`M145.29003,2.7097656 C143.570709,0.975547058 141.229771,1.49332653e-16 138.787634,
0 C136.345497,-1.50064675e-16 134.004559,0.975547058 132.285237,2.7097656 L74,60.9906013 L15.7147626,
2.7097656 C13.9954415,0.975547058 11.6545034,1.49332653e-16 9.21236641,0 C6.7702294,-1.50064675e-16 4.42929133,
0.975547058 2.70997026,2.7097656 C0.975620737,4.42895683 2.9941994e-16,6.76971811 0,9.21167069 C-2.98687862e-16,
11.6536233 0.975620737,13.9943846 2.70997026,15.7135758 L60.9952077,73.9944115 L2.70997026,132.275247 C0.975620737,
133.994438 8.56750993e-15,136.3352 6.09967468e-15,138.777152 C3.63110734e-15,141.219105 0.975620737,
143.559866 2.70997026,145.279057 C4.47905415,147.048008 6.86259086,148 9.17860527,148 C11.4946197,
148 13.8781564,147.115525 15.6472403,145.279057 L73.9324777,86.9982217 L132.217715,145.279057 C133.986799,
147.048008 136.370336,148 138.679598,148 C141.069887,148 143.379149,147.115525 145.154985,145.279057 C146.882658,
143.556308 147.853658,141.216879 147.853658,138.777152 C147.853658,136.337425 146.882658,133.997997 145.154985,
132.275247 L87.0047923,73.9944115 L145.29003,15.7135758 C147.024379,13.9943846 148,11.6536233 148,9.21167069 C148,
6.76971811 147.024379,4.42895683 145.29003,2.7097656 L145.29003,2.7097656 Z`}
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default CloseNoCircle;
