import React from 'react';

/* eslint-disable */
const Clock = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path d="M146.405851,59.1779574 C145.885489,56.6218085 143.393894,54.9749149 140.834596,55.4921277 C140.494511,55.5614043 140.170957,55.6676809 139.866298,55.8030851 C137.879319,56.6808511 136.697681,58.8473191 137.148766,61.063383 C142.158723,85.668383 132.310426,111.159809 112.057255,126.006255 C98.2507447,136.126936 81.2142128,140.246532 64.0855745,137.609298 C46.9569362,134.970489 31.9498936,125.914149 21.8292128,112.106851 C0.786446809,83.398 7.02134043,42.9223617 35.728617,21.8780213 C49.5351277,11.7581277 66.5724468,7.63853191 83.7002979,10.2749787 C100.334553,12.8374255 114.954277,21.4670851 125.054489,34.608383 L120.712106,33.9841064 C118.127617,33.6109574 115.735213,35.4050638 115.364426,37.9871915 C114.992851,40.5693191 116.785383,42.9632979 119.367511,43.3348723 L135.045277,45.5895106 C135.149191,45.6044681 135.251532,45.6036809 135.355447,45.6115532 C135.478255,45.621 135.601851,45.6383191 135.723872,45.6383191 C138.035191,45.6383191 140.054447,43.9402553 140.393745,41.5864255 L142.648383,25.9086596 C143.019957,23.3265319 141.227426,20.9325532 138.645298,20.5609787 C136.062383,20.188617 133.669191,21.9819362 133.297617,24.5640638 L132.65917,29.0032766 C121.067149,13.8395745 104.260489,3.88342553 85.1393617,0.938382979 C65.5175532,-2.08617021 45.9862766,2.64668085 30.144766,14.258383 C14.3024681,25.8724468 3.91255319,43.0742979 0.890361702,62.6961064 C-2.13182979,82.3187021 2.59787234,101.849191 14.2111489,117.691489 C25.8236383,133.533 43.0254894,143.922128 62.6480851,146.945106 C66.5165532,147.541043 70.3795106,147.835468 74.2133404,147.835468 C89.8265532,147.835468 104.924915,142.947532 117.642681,133.624319 C140.858213,116.605894 152.148723,87.383766 146.405851,59.1779574 Z"/>
    <path d="M99.664617,75.7090851 L83.5420638,75.7090851 C82.3596383,73.0332766 80.2089149,70.881766 77.5331064,69.7001277 L77.5331064,33.4605957 C77.5331064,30.8524894 75.4178085,28.7371915 72.8097021,28.7371915 C70.2015957,28.7371915 68.0862979,30.8524894 68.0862979,33.4605957 L68.0862979,69.7001277 C63.9643404,71.5217872 61.076766,75.6437447 61.076766,80.4324894 C61.076766,86.9019787 66.3402128,92.1654255 72.8097021,92.1654255 C77.5984468,92.1654255 81.7204043,89.2778511 83.5420638,85.1558936 L99.664617,85.1558936 C102.272723,85.1558936 104.388021,83.0405957 104.388021,80.4324894 C104.388021,77.824383 102.272723,75.7090851 99.664617,75.7090851 Z M70.5227872,80.4324894 C70.5227872,79.1713404 71.5485532,78.1455745 72.8089149,78.1455745 C74.0692766,78.1455745 75.0958298,79.1713404 75.0958298,80.4324894 C75.0958298,81.6936383 74.0700638,82.718617 72.8089149,82.718617 C71.547766,82.718617 70.5227872,81.6928511 70.5227872,80.4324894 Z"/>
  </svg>
);
/* eslint-enable */

export default Clock;
