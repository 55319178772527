import React from 'react';

/* eslint-disable */
const People = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path d="M147.616125 141.522687C145.842438 133.650937 132.4415 84.771625 73.9514375 84.771625 10.6536875 84.771625.1641875 142.001375.06475 142.581812-.161875 143.925375.208125 145.30825 1.0915 146.351187 1.9725625 147.396437 3.269875 148 4.63425 148L143.270938 148 143.36575 148C145.921063 148 148 145.925687 148 143.361125 148 142.709 147.863563 142.082312 147.616125 141.522687zM10.5796875 138.72225C14.6080625 125.69825 29.0265 94.0516875 73.949125 94.0516875 118.87175 94.0516875 133.2925 125.69825 137.320875 138.72225L10.5796875 138.72225zM73.9468125 76.0696875C94.905 76.0696875 111.955062 59.010375 111.955062 38.036 111.95275 17.0639375 94.905 0 73.9468125 0 52.9955625 0 35.9524375 17.0639375 35.9524375 38.0336875 35.9524375 59.010375 52.9955625 76.0696875 73.9468125 76.0696875L73.9468125 76.0696875zM73.9468125 9.27775C89.7966875 9.27775 102.686563 22.176875 102.686563 38.036 102.686563 53.8905 89.799 66.79425 73.9468125 66.79425 58.1085 66.79425 45.2209375 53.8928125 45.2209375 38.036 45.2209375 22.176875 58.1085 9.27775 73.9468125 9.27775z" />
  </svg>
);
/* eslint-enable */

export default People;
