import React from 'react';

/* eslint-disable */
const Comment = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path d="M137.990055,26.1850909 C131.387,18.1457182 122.417969,11.7956045 111.085273,7.13532273 C99.7528672,2.47561364 87.3720312,0.145186364 73.9471016,0.145186364 C63.9331094,0.145186364 54.3596484,1.52116364 45.2267187,4.27369091 C36.0937891,7.02593182 28.226375,10.7460818 21.6238984,15.4332818 C15.0202656,20.1210545 9.76713281,25.6942636 5.86016406,32.1526227 C1.95319531,38.6112682 0,45.3840545 0,52.4698364 C0,60.6452318 2.48882812,68.3057455 7.46850781,75.4459364 C12.4481875,82.5852682 19.2558984,88.5817227 27.8948203,93.4327227 C27.2889453,95.6122364 26.5738047,97.6840773 25.7482422,99.6448091 C24.9229687,101.606973 24.1803672,103.216909 23.5201484,104.470323 C22.8593516,105.723736 21.9661484,107.114318 20.8373594,108.64035 C19.7103047,110.166095 18.8575703,111.243109 18.2797344,111.8691 C17.7024766,112.496236 16.7534844,113.533445 15.4321797,114.975859 C14.1126094,116.421423 13.2592969,117.360982 12.8745547,117.797973 C12.8187656,117.823459 12.5990781,118.070591 12.2140469,118.533068 C11.8293047,118.995545 11.6362109,119.226927 11.6362109,119.226927 L11.1410469,119.961736 C10.8655703,120.371523 10.7418516,120.630109 10.7690234,120.736923 C10.7967734,120.843736 10.7418516,121.118073 10.6042578,121.553345 C10.466375,121.990336 10.4805391,122.31765 10.6453047,122.536432 L10.6453047,122.617759 C10.8664375,123.5997 11.3477266,124.389491 12.0900391,124.987991 C12.8323516,125.587064 13.6723672,125.832764 14.6071953,125.721941 C18.1843437,125.286095 21.3200937,124.689314 24.0156016,123.924723 C38.4311484,120.271295 51.0860156,113.678059 61.9793359,104.137855 C66.1048359,104.5737 70.0938984,104.792482 73.9453672,104.792482 C87.3700078,104.792482 99.7502656,102.462055 111.083828,97.8003409 C122.417391,93.1406318 131.385555,86.7902318 137.987453,78.7522909 C144.590508,70.7163545 147.891602,61.9533409 147.891602,52.46955 C147.891891,42.9854727 144.591086,34.2230318 137.990055,26.1850909 Z" transform="translate(0 12)"/>
  </svg>
);
/* eslint-enable */

export default Comment;
