import React from 'react';

/* eslint-disable */
const Good = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path fillRule="evenodd" d="M10.48,109.750022 L25.776,109.750022 L25.776,56.7520474 L10.48,56.7520474 L10.48,109.750022 Z M96.84,111.662134 L44.936,111.662134 C40.164,111.662134 36.28,107.769763 36.28,102.987478 L36.28,57.9947198 C37.268,57.7020905 43.052,55.890194 48.9,51.7092026 C57.672,45.4637716 62.308,37.0536853 62.308,27.3889009 L62.308,10.552694 C64.876,10.3843319 67.412,10.9094612 69.016,12.0879957 C71.372,13.8197198 72.564,17.3432974 72.564,22.5545043 L72.564,44.549806 C72.564,47.4480388 74.916,49.8091164 77.816,49.8091164 L107.612,49.8051078 C109.22,49.9133405 110.736,50.4545043 112.032,51.3965302 C114.596,53.2565302 115.912,56.6077371 115.376,59.9509267 L108.752,102.346099 C108.472,104.651056 107.612,111.662134 96.84,111.662134 L96.84,111.662134 Z M118.16,42.8782112 C114.912,40.5291595 111.064,39.2864871 107.032,39.2864871 L83.048,39.2864871 L83.048,22.5545043 C83.048,13.8157112 80.404,7.43799569 75.184,3.59773707 C71.94,1.20859914 67.92,0.00200431034 63.236,0.00200431034 C59.096,0.00200431034 55.848,0.984116379 55.496,1.09234914 C53.304,1.77782328 51.832,3.78614224 51.832,6.09109914 L51.832,27.3688578 C51.832,33.6383405 48.916,38.7733836 42.916,43.070625 C39.068,45.8325647 35.1,47.3117457 33.56,47.8248491 C32.3,46.8186853 30.74,46.2534698 29.08,46.2534698 L7.18,46.2534698 C3.224,46.2534698 0,49.4804095 0,53.448944 L0,113.081185 C0,117.045711 3.224,120.272651 7.18,120.272651 L29.1,120.272651 C30.844,120.272651 32.48,119.627263 33.748,118.548944 C36.988,120.889978 40.916,122.184763 44.936,122.184763 L96.84,122.184763 C109.376,122.184763 117.716,115.265884 119.132,103.777177 L125.764,61.5744181 C126.896,54.3228233 123.904,46.9830388 118.16,42.8782112 L118.16,42.8782112 Z" transform="translate(10 12)"/>
  </svg>
);
/* eslint-enable */

export default Good;
