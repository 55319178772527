import React from 'react';

/* eslint-disable */
const Facebook = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path fill-rule="evenodd" d="M109,47.9230801 L85.1604597,47.9230801 L85.1604597,32.5624508 C85.1604597,26.7937901 89.0521205,25.4488926 91.7931219,25.4488926 L108.616447,25.4488926 L108.616447,0.0888428897 L85.447345,0 C59.727459,0 53.8743763,18.9143449 53.8743763,31.0184227 L53.8743763,47.9230801 L39,47.9230801 L39,74.0551439 L53.8743763,74.0551439 L53.8743763,148 L85.1604597,148 L85.1604597,74.0551439 L106.271472,74.0551439 L109,47.9230801 L109,47.9230801 Z"/>
  </svg>
);
/* eslint-enable */

export default Facebook;
