import React from 'react';

/* eslint-disable */
const Magnifiner = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path d="M60.2523446,120.503385 C75.1785042,120.503385 88.8220019,115.016701 99.3594914,106.00019 L139.985703,146.625988 C140.903228,147.541216 142.101627,148 143.304708,148 C144.505448,148 145.703848,147.541216 146.623713,146.625988 C147.541238,145.70608 148,144.509964 148,143.306826 C148,142.103688 147.541238,140.902891 146.623713,139.985323 L105.997501,99.3595243 C115.015926,88.8262162 120.502349,75.1773898 120.502349,60.2528627 C120.502349,27.0284684 93.4704813,0 60.2523446,0 C27.0295267,0 0,27.0284684 0,60.2528627 C-1.26032814e-14,93.4749162 27.0295267,120.503385 60.2523446,120.503385 Z M60.2523446,9.38868856 C88.2930208,9.38868856 111.111765,32.2038337 111.111765,60.2505219 C111.111765,88.2925286 88.2930208,111.112355 60.2523446,111.112355 C32.2093277,111.112355 9.39058373,88.2948694 9.39058373,60.2505219 C9.39058373,32.2038337 32.2093277,9.38868856 60.2523446,9.38868856 Z"/>
  </svg>
);
/* eslint-enable */

export default Magnifiner;
