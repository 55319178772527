import React from 'react';

/* eslint-disable */
const Close = (props) => (
  <svg {...props} width="64" xmlns="http://www.w3.org/2000/svg" height="64" viewBox="0 0 64 64">
    <path d="M46.355 17.979c-.779-.78-2.043-.78-2.821 0l-11.594 11.591-11.591-11.591c-.779-.78-2.044-.78-2.822 0-.778.779-.778 2.043 0 2.823l11.499 11.5-11.492 11.489c-.778.779-.778 2.043 0 2.822.392.391.903.586 1.414.586s1.02-.195 1.411-.586l11.581-11.579 11.583 11.579c.39.391.899.586 1.41.586.512 0 1.024-.195 1.412-.586.779-.779.779-2.043 0-2.822l-11.489-11.488 11.499-11.5c.78-.781.78-2.044 0-2.824zM31.94 0c-17.61 0-31.94 14.328-31.94 31.941 0 17.611 14.33 31.94 31.94 31.94 17.611 0 31.941-14.329 31.941-31.94.001-17.613-14.329-31.941-31.941-31.941zm0 59.89c-15.411 0-27.948-12.538-27.948-27.948 0-15.412 12.537-27.949 27.948-27.949 15.412 0 27.949 12.537 27.949 27.949.001 15.41-12.536 27.948-27.949 27.948z"/>
  </svg>
);
/* eslint-enable */

export default Close;
