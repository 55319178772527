import React from 'react';

/* eslint-disable */
const Email = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path fill-rule="evenodd" d="M134.125141,116.776857 L13.8748592,116.776857 C11.3243005,116.776857 9.25140821,114.710298 9.25140821,112.167532 L9.25140821,38.6384612 L71.2984807,83.2491822 C72.1051061,83.8287172 73.0514265,84.1162385 74.0022531,84.1162385 C74.9485735,84.1162385 75.8994002,83.8287172 76.7060256,83.2491822 L138.748592,38.6384612 L138.748592,112.167532 C138.748592,114.710298 136.6757,116.776857 134.125141,116.776857 L134.125141,116.776857 Z M131.853972,32.223143 L74.0022531,73.8193833 L16.1460281,32.223143 L131.853972,32.223143 Z M134.125141,23 L13.8748592,23 C6.22318302,23 0,29.2086623 0,36.8324683 L0,112.167532 C0,119.79583 6.22318302,126 13.8748592,126 L134.125141,126 C141.776817,126 148,119.79583 148,112.167532 L148,36.8324683 C148,29.2086623 141.776817,23 134.125141,23 L134.125141,23 Z"/>
  </svg>
);
/* eslint-enable */

export default Email;
