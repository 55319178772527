import React from 'react';

/* eslint-disable */
const Warning = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <g fillRule="evenodd" transform="translate(0 5)">
      <ellipse cx="73.959" cy="111.077" rx="5.2" ry="5.205"/>
      <path d="M69.3353998,53.0952988 L69.3353998,93.02983 C69.3353998,95.5839972 71.4030146,97.6559413 73.9592965,97.6559413 C76.5155783,97.6559413 78.5831932,95.5863045 78.5831932,93.02983 L78.5831932,53.0952988 C78.5831932,50.5411316 76.5155783,48.4668802 73.9592965,48.4668802 C71.4030146,48.4668802 69.3353998,50.5388243 69.3353998,53.0952988 L69.3353998,53.0952988 Z"/>
      <path d="M146.982749,132.05529 L78.0392053,2.49648502 C76.4325972,-0.521446964 71.4836908,-0.521446964 69.8793877,2.49648502 L0.938148528,132.05529 C0.175182526,133.490423 0.218978158,135.220888 1.05570522,136.612183 C1.89012726,138.008091 3.39531396,138.861788 5.02036239,138.861788 L142.898231,138.861788 C144.523279,138.861788 146.026161,138.010399 146.865193,136.612183 C147.69731,135.220888 147.74341,133.490423 146.982749,132.05529 L146.982749,132.05529 Z M12.7214784,129.604951 L73.9592965,14.5151453 L135.19942,129.604951 L12.7214784,129.604951 L12.7214784,129.604951 Z"/>
    </g>
  </svg>
);
/* eslint-enable */

export default Warning;
