import React from 'react';

/* eslint-disable */
const Coin = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <g>
      <path d="M73.9145352,0 C33.1582535,0 0,33.1582535 0,73.9145352 C0,114.670817 33.1582535,147.82907 73.9145352,147.82907 C114.670817,147.82907 147.82907,114.670817 147.82907,73.9145352 C147.82907,33.1582535 114.671512,0 73.9145352,0 Z M73.9145352,138.102066 C38.5216901,138.102066 9.72769953,109.30738 9.72769953,73.9145352 C9.72769953,38.5216901 38.5216901,9.72769953 73.9145352,9.72769953 C109.30738,9.72769953 138.101371,38.5216901 138.101371,73.9145352 C138.101371,109.30738 109.30738,138.102066 73.9145352,138.102066 Z"/>
      <path d="M78.778385,69.5502723 L78.778385,41.934723 C81.5306291,42.9568263 83.9319812,44.8321878 85.6016714,47.368338 C87.0795869,49.6119624 90.0958685,50.2331455 92.3387981,48.7559249 C94.5824225,47.2787042 95.2036056,44.2624225 93.7256901,42.0187981 C90.2313615,36.7130329 84.8470798,33.1110047 78.778385,31.8415399 L78.778385,24.1149671 C78.778385,21.4287324 76.60077,19.2511174 73.9145352,19.2511174 C71.2283005,19.2511174 69.0506854,21.4287324 69.0506854,24.1149671 L69.0506854,31.8269484 C58.2855962,34.0712676 50.1733897,43.6315117 50.1733897,55.0525258 C50.1733897,66.4735399 58.2862911,76.0351737 69.0506854,78.279493 L69.0506854,105.895042 C66.2984413,104.872939 63.8970892,102.997577 62.2273991,100.461427 C60.7494836,98.2178028 57.7332019,97.5959249 55.4902723,99.0738404 C53.2466479,100.551061 52.6254648,103.567343 54.1033803,105.810967 C57.5977089,111.116732 62.9819906,114.718761 69.0506854,115.988225 L69.0506854,123.714103 C69.0506854,126.400338 71.2283005,128.577953 73.9145352,128.577953 C76.60077,128.577953 78.778385,126.400338 78.778385,123.714103 L78.778385,116.002817 C89.5434742,113.758498 97.6556808,104.198254 97.6556808,92.7772394 C97.6556808,81.3562254 89.5434742,71.7945915 78.778385,69.5502723 Z M59.9010892,55.0525258 C59.9010892,49.0401127 63.7185164,43.9163944 69.0506854,41.9381972 L69.0506854,68.1668545 C63.7185164,66.1886573 59.9010892,61.064939 59.9010892,55.0525258 L59.9010892,55.0525258 Z M78.778385,105.890873 L78.778385,79.662216 C84.110554,81.6404131 87.9279812,86.7641315 87.9279812,92.7765446 C87.9279812,98.7889577 84.1112488,103.912676 78.778385,105.890873 Z"/>
    </g>
  </svg>

);
/* eslint-enable */

export default Coin;
