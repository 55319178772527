import React from 'react';

/* eslint-disable */
const Bookmark = (props) => (
  <svg {...props} width="148" height="148" viewBox="0 0 148 148">
    <path d="M39.7038958,0 C37.1073453,0 35,2.07196763 35,4.62955266 L35,142.4154 C35,144.121998 35.9595947,145.694474 37.4954167,146.499211 C39.0241828,147.299323 40.8892775,147.204512 42.3286696,146.256402 L73.9459052,125.335542 L105.596068,147.160576 C106.405138,147.715567 107.350621,148 108.296104,148 C109.03932,148 109.784887,147.828878 110.4646,147.475071 C112.026294,146.679583 113,145.10017 113,143.375072 L113,4.62955266 C113,2.07428009 110.895007,6.68714609e-15 108.296104,6.68714609e-15 L39.7038958,0 Z"/>
  </svg>
);
/* eslint-enable */

export default Bookmark;
